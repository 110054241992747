.linearProgressColor .MuiLinearProgress-barColorSecondary {
    background-color: #019F48 !important;
}
.linearProgressColor .MuiLinearProgress-barColorPrimary {
    background-color: #FD8D02 !important;
}
.MuiLinearProgress-colorPrimary {
    background-color: #D9D9D9 !important;
}
.graphLoginImage {
    width: 250px;
    height: 250px;
    /* margin-top: 22px; */
    z-index: 1; 
    margin-left: 0px

}
.forgotGraphImage {
    width: 250px;
    height: 250px;
    margin-top: 22px;
    z-index: 1;
    margin-left: -16px;

}
.graphImage {
    width: 250px;
    height: 250px;
      margin-top: 32px;
      z-index: 1; 
      margin-left: -30px
}
.watchImage {
    width: 309px !important;
    height: 309px !important;
    right: 0;
    top: 150px !important; 
    left: 166px !important;
    position: absolute;
     
}
.regwatchImage {
    width: 309px !important;
    height: 309px !important;
    right: 0;
    top: 150px !important;
    left: 200px !important;
    position: absolute;
     
}
.forgotWatchImg{
    width: 250px !important; 
    height: 250px !important; 
    right: 0;
    top: 322px !important;
    left: 405px !important;
     position: absolute
}
.watch { 
    width: 250px !important; 
    height: 250px !important; 
    right: 0;
     top: 366px !important; 
     left: 372px !important;  
     position: absolute
     }
     .imageLoginUpload {
        width: 280px; 
        height: 200px; 
        right: 0px;
        margin-top: 45px;
        margin-left: 0px;
        z-index: 1
     }
     .imageUpload { 
        width: 280px; 
        height: 200px; 
        right: 0px;
        margin-top: 38px;
        margin-left: -12px;
        z-index: 1
   }
   .forgotImageUpload {
    width: 280px; 
    height: 200px; 
    right: 0px;
    margin-top: 27px;
    margin-left: 20px;
    z-index: 1
   }
   .creditLiftLogo { 
    width: 185px; 
    height: 53px; 
    margin-bottom: 20px;
}
   .verticaliLine { 
    position: absolute;
    top: 0px; left: 195px; transform: rotate(-1deg);
     width: 41%
   }
   .loginVerticaliLine { 
    position: absolute;
    top: 0px; left: 40px; transform: rotate(-1deg);
     width: 38%;
   }
   .regVerticaliLine {
    position: absolute;
    top: 0px; left: 40px; transform: rotate(-1deg);
     width: 41%
}
.inputLabel {
    font-size: 18px;
    font-family: 'Urbanist';
    color: #333333;
}
   .forgotVerticaliLine { 
    position: absolute;
    top: 0px; left: 40px; transform: rotate(-1deg);
     width: 41%;
   }
   @media (max-width: 900px) {
   .forgotWatchImg{
        width: 150px !important; 
        height: 150px !important; 
        right: 0;
        top: 195px !important;
        left: 165px !important; 
         right:0px !important;
         position: absolute;
         object-fit: contain;
         align-self:center;
         z-index: 999;
        
    }
    .watch { 
        width: 150px !important; 
        height: 150px !important; 
        right: 0;
        top: 188px !important;
        left: 160px !important; 
         right:0px !important;
         position: absolute;
         object-fit: contain;
         align-self:center;
         z-index: 999;
         }
         .watchImage { 
            width: 150px !important; 
            height: 150px !important; 
            right: 0;
            top: 100px !important;
            left: 50px !important;
             right:0px !important;
             position: absolute;
             object-fit: contain;
             align-self:center;
             z-index: 999;
             }
         .imageLoginUpload {
            width: 150px; 
            height: 150px; 
            right: 0px;
            margin-top: 0px;
            margin-left: -10px; 
              z-index: 999;
              object-fit: contain;
         }
         .imageUpload { 
            width: 150px; 
            height: 150px; 
            right: 0px;
            margin-top: 20px;
            margin-left: -20px; 
              z-index: 999;
              object-fit: contain;
       }
     .forgotGraphImage {
        width: 150px;
        height: 150px;
          margin-top: 32px;
          z-index: 1; 
          margin-left: -35px;
          object-fit: contain;
       }
       .graphLoginImage {
        width: 150px;
        height: 150px;
          margin-top: 32px;
          z-index: 1; 
          margin-left: -55px;
          object-fit: contain;
       }
       .forgotWatchImg{
        width: 150px !important; 
        height: 150px !important; 
        right: 0;
        top: 195px !important;
        left: 165px !important; 
         right:0px !important;
         position: absolute;
         object-fit: contain;
         align-self:center;
         z-index: 999;
        
    }
    .forgotImageUpload {
        width: 150px; 
        height: 150px; 
        right: 0px;
        margin-top: 25px;
        margin-left: -12px; 
          z-index: 999;
          object-fit: contain;
   }
       .graphImage {
        width: 150px;
        height: 150px;
          margin-top: 32px;
          z-index: 1; 
          margin-left: -30px;
          object-fit: contain;
    }
    .forgotGraphImage {
        width: 150px;
        height: 150px;
          margin-top: 32px;
          z-index: 1; 
          margin-left: -35px;
          object-fit: contain;
       }
    .creditLiftLogo { 
      display: none; 
    }
    .verticaliLine {
        position: absolute;
        top: 0px;
        left: -34px;
        transform: rotate(-1deg);
        width: 72%;
        object-fit: contain;
   }
   .loginVerticaliLine {
    position: absolute;
    top: 0px;
    left: -34px;
    transform: rotate(-1deg);
    width: 72%;
    object-fit: contain;
}
.regVerticaliLine {
    position: absolute;
    top: 0px;
    left: -34px;
    transform: rotate(-1deg);
    width: 72%;
    object-fit: contain;
}
   .forgotVerticaliLine {
    position: absolute;
    top: 0px;
    left: -34px;
    transform: rotate(-1deg);
    width: 72%;
    object-fit: contain;
}
.regVerticaliLine {
    position: absolute;
    top: 0px;
    left: -34px;
    transform: rotate(-1deg);
    width: 72%;
    object-fit: contain;
}

}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
    border-radius: 12px !important;

}

.muiStepper .MuiTypography-body1{
    font-size: 26px;
    font-family: Urbanist;
    font-weight: 500;

}
.start .MuiStepper-root{
    padding:0px;
}
.muiStepper .MuiStepConnector-lineVertical {
    min-height: 50;
    border-left-style: solid;
    border-left-width: 3px;
    margin-top:8px ;
}
  .MuiOutlinedInput-root {
    border-radius: 12px !important;
    border: 1px solid #CEE1D6 !important;
    background: #F4FAF7 ;
  }
  .Mui-focused {
    border-color: #019F48 !important;
  }
.MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0) !important;
}
/* .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: #f44336 !important;
} */
.accept-Rem.Mui-checked .MuiIconButton-root {
  border-color: #019f48 !important;
}
.accept-Rem .MuiIconButton-root {
  border-color: #cee1d6 !important;
  border-radius: 12px !important;
}

.accept-Rem .MuiSvgIcon-root {
  fill: #cee1d6 !important;
  font-size: 1.4em !important;
}
.accept-Rem .MuiSvgIcon-root path {
  stroke-width: 0.5px !important; /* Decrease the border width of the checkbox */
  stroke-linecap: round; /* Make the borders round */
  stroke-linejoin: round; /* Make the borders round */
}
.accept-Rem .MuiIconButton-root .PrivateSwitchBase-input-23 {
  width: 1px !important; /* Make the border width very thin */
}
.accept-Rem.Mui-checked .MuiSvgIcon-root {
  fill: #019f48 !important;
}
.accept-check.Mui-checked .MuiIconButton-root {
  border-color: #019f48 !important;
}
.accept-check .MuiIconButton-root {
  border-color: #cee1d6 !important;
  border-radius: 12px !important;
}
.accept-check .MuiIconButton-root .PrivateSwitchBase-input-23 {
  width: 1px !important; /* Make the border width very thin */
}
.accept-check .MuiSvgIcon-root {
  fill: #cee1d6 !important;
  font-size: 1.95rem !important;
}
.accept-check .MuiSvgIcon-root path {
  stroke-width: 0.5px !important; /* Decrease the border width of the checkbox */
  stroke-linecap: round; /* Make the borders round */
  stroke-linejoin: round; /* Make the borders round */
}
.accept-check.Mui-checked .MuiSvgIcon-root {
  fill: #019f48 !important;
}

   .input-error .MuiOutlinedInput-root {
      border-color: #FF0000 !important;
    }