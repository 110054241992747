.creditLiftLogo{
    width:135px;
    height:40px;
}
.txtLableStarted{
    width:255;
    height:27;
    margin-top:15px;
}
.txtInfo{
    padding-top:10px;
}
.heading{
    color: #333;
    font-family: Urbanist;
    padding-left: 20px;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px; 
}
.subHeading{
    color:#333;
    font-family: Urbanist;
    padding-left: 20px;
    padding-top: 15px;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px; /* 134.615% */
}
.addressDetail{
    padding-top:30px;
    padding-bottom: 10px;
    padding-left:20px !important;
    color: #333;
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px; /* 145.833% */
}
.btnStyle{
    background:#019F48 !important;
    padding:7 40;
    border: 1px;
    border-radius: 12px;
    
}
.backbtnStyle{
    background:#FFF !important;
    padding:7 40;
    border: 1px solid #9E9E9E;
    border-radius: 12px;
    
}
.muiStepper .MuiStepIcon-root.MuiStepIcon-completed {
    color: #019F48 !important;
}
.muiStepper .MuiStepIcon-root.MuiStepIcon-active{
    border-color: #019F48 !important;
}
.muiStepper .MuiStepLabel-label.MuiStepLabel-completed{
    color: #019F48 !important;
    font-family: 'Urbanist';
}
.muiStepper .MuiStepLabel-label.MuiStepLabel-active circle{
    color: #019F48 !important;
    border-color: #019F48 !important;
    font-family: 'Urbanist';
}
.muiStepper .MuiStepIcon-root.MuiStepIcon-active{
    color: #FFFF !important;
    border-color: #019F48 !important;
    border-width: 3px;
    font-size: 22px;
    font-family: 'Urbanist';
}
.muiStepper .MuiStepConnector-line {
    display: block;
}
.muiStepper .MuiStepLabel-label.MuiStepLabel-active {
    color:#019F48 !important;
    font-weight: 600;
    font-family: 'Urbanist';
    
}
.muiStepper .MuiStepConnector-vertical {
    padding: 0 0 0px !important;
    margin-left: 11px;
    height: 50px;
}
.muiStepper .MuiStepConnector-active .MuiStepConnector-line {
    border-color: #019F48 !important;
    font-family: 'Urbanist';
}
.MuiStepLabel-label{
    border-color: rgba(0, 0, 0, 0.38) !important;
    color: #333333 !important;
    font-family: 'Urbanist';
    font-size: 26px;
}
.muiStepper .MuiStepIcon-root.MuiStepIcon-active{
    display: block;
    color: #FFFF !important;
    border-radius: 12px !important;
    border-color: #019F48 !important ;
    font-family: 'Urbanist';
}
.muiStepper .MuiStepIcon-root .MuiStepLabel-completed {
    border-radius: 12px !important;
    border: 1px solid #019F48 !important;
    border-color: #019F48 !important ;
    font-family: 'Urbanist';
}
 .MuiStepConnector-completed .MuiStepConnector-line{
    border-left-width: 3px  !important;
    border-left-color: #019F48;

}
.muiStepper .MuiStepIcon-root {
    color: #FFFF !important;
    display: block !important;
    border-radius: 12px !important;
    border: 1px solid #019F48 !important;
    border-color: #CEE1D6 !important ;
}
.muiStepper .MuiBox-root-30 {
    border-color: rgba(0, 0, 0, 0.23) !important;
    border: 1px solid rgba(0, 0, 0, 0.23) !important; 
}
.muiStepper .MuiBox-root-22 {
    border-color: rgba(0, 0, 0, 0.23) !important;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;

}
.linearProgressColor .MuiLinearProgress-barColorSecondary {
    background-color: #019F48 !important;
}
.linearProgressColor .MuiLinearProgress-barColorPrimary {
    background-color: #FD8D02 !important;
}
.MuiLinearProgress-colorPrimary {
    background-color: #D9D9D9 !important;
}
.muiStepper{
    background-color: #EEFFF8;
  }
.leftView  {
    max-width : 480px;
}